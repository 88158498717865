@font-face {
  font-family: 'ITC Avant Garde Gothic Pro';
  src: url('./assets/font/ITC\ Avant\ Garde\ Gothic\ Pro-Bold.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Pro';
  src: url('./assets/font/ITC\ Avant\ Garde\ Gothic\ Pro-Book.otf') format('opentype');
  font-weight: 400; /* Book font-weight */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Pro';
  src: url('./assets/font/ITC\ Avant\ Garde\ Gothic\ Pro-Demi.otf') format('opentype');
  font-weight: 600; /* Demi font-weight */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Pro';
  src: url('./assets/font/ITC\ Avant\ Garde\ Gothic\ Pro-Medium.otf') format('opentype');
  font-weight: 500; /* Medium font-weight */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Avant Gaarde Gothic Pro';
  src: url('./assets/font/ITC\ Avant\ Garde\ Gothic\ Pro-XLt.otf') format('opentype');
  font-weight: 100; /* XLt font-weight */
  font-style: normal;
  font-display: swap;
}


:root{
  background-color: black !important;
  color: rgb(255, 255, 255) !important;
}


h1,h2,a{
  text-transform: uppercase !important;
}
::-webkit-scrollbar{
  width:10px;
}

::-webkit-scrollbar-track{
  background: #141414;
}

::-webkit-scrollbar-thumb{

  background: #ffffff;
}

html{
  scroll-snap-type: y mandatory;
}
body {
  font-family: 'ITC Avant Garde Gothic Pro', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.viewport-filler {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000; 
}

a {
  text-decoration: none;
}

@media (min-width: 769px) and (max-width: 1200px) {
 p {
   font-size: 16; 
  }
}