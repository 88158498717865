.model-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.model {
  margin-top: 10vh;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 35vw;
  color: black;
}

/* .model .model-title h2,
p {
  color: black;
  list-style: none;
} */

.model-title h1 {
  color: black;
  font-size: 42px;
}

.model-disc2 {
  color: black;
  font-size: 28px;
}

.model-disc3 {
  color: black;
  font-size: 18px;
}

.model-content {
  margin-bottom: 20px;
}

.model-buttons {
  display: flex;
  justify-content: right;
}

.model-button {
  cursor: pointer;
  border: none;
  border-radius: 5px;

  margin: 0 10px;
  font-size: 1rem;
  outline: none;
}

.model-cancel {
  background-color: transparent;
  color: black;
  font-size: larger;
}

.model-inqurie {
  background-color: black;
  color: white;
  padding: 10px 20px;
}

.model-inqurie a {
  color: white;
  text-decoration: none;
}
@media (max-width: 768px) {
  .model {
    width: 80vw; /* Adjust the width for smaller screens */
  }

  .model-title h1 {
    font-size: 30px; /* Reduce font size for smaller screens */
  }

  .model-button {
    margin: 0 5px; /* Adjust margin for smaller screens */
  }

  .model-disc2 {
    color: black;
    font-size: 20px;
  }

  .model-disc3 {
    color: black;
    font-size: 15px;
  }

  .model-ad-image{
    height: 60vh;
    width: auto;
  }
}


.model-ad-image{
  height: 65vh;
  width: auto;
}